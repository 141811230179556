import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DstButton from "../../components/DstButton/DstButton";
import { Helmet } from "react-helmet";

const PoseSerrure = () => {
  const navigate = useNavigate();
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>
            Installation & changement de serrure - Serrurerie Guy Môquet Paris
          </title>
          <meta
            name="description"
            content="Découvrez nos services d'installation et de changement de serrure. Nos serruriers qualifiés résolvent vos problèmes de serrure et augmentent la sécurité de votre domicile."
          />
        </Helmet>
        <section className="container my-5">
          <div className="container-fluid">
            <Row className="d-flex flex-column-reverse flex-md-row">
              <Col className="align-self-center text-center text-md-start">
                <img
                  src="../../assets/images/changement-serrure.webp"
                  alt="changement serrure"
                  className="img-fluid"
                  loading="lazy"
                />
              </Col>
              <Col md="8">
                <h2 className="line-title">
                  Installation & changement de serrure
                </h2>
                <h3>Porte blindée de haute sécurité</h3>
                <p>
                  Vous rencontrez un problème avec votre{" "}
                  <strong>serrure</strong> ou mécanisme de fermeture de porte ?
                  Votre <strong>serrure</strong> est trop défectueuse, vous avez
                  subi un cambriolage ou vous emménagez ?
                </p>
                <p>
                  Formé et qualifié, notre <strong>serrurier</strong> saura vous
                  guider et vous conseiller sur votre changement de{" "}
                  <strong>serrure</strong> en choisissant la{" "}
                  <strong>serrure</strong> la plus optimale pour votre
                  situation. Il effectuera un changement de{" "}
                  <strong>serrure</strong> rapide et efficace et pourra vous
                  installer une <strong>serrure</strong> de norme A2P qui
                  augmentera la sécurité de votre domicile, et qui représente
                  également une certification fiable auprès des assurances.
                </p>
                <p>
                  Les dommages avancés aux <strong>serrures</strong> devraient
                  être un signe pour remplacer les <strong>serrures</strong>{" "}
                  immédiatement. Tant que votre porte n'a pas subi d'effraction
                  importante, le remplacement de <strong>serrures</strong>{" "}
                  obsolètes ne ralentira pas le processus. Vous devriez vous
                  soucier de ce travail si vos meubles ou vos portes de garage
                  sont plus anciens et moins fonctionnels. Prendre note de ces
                  imperfections rend impératif que vous restiez sur vos gardes.
                  Sinon, vous risquez de vous retrouver avec une mauvaise
                  surprise.
                </p>
              </Col>
            </Row>
            <p>
              Si vous devez installer ou remplacer des serrures, ou si vous
              oubliez vos clés chez vous, vous aurez besoin de l'intervention
              d'un professionel serrurier de confiance.
            </p>

            <Link className="orange-link" to={"/contact"}>
              Vous souhaitez en savoir plus sur les tarifs, demandez un devis
            </Link>
          </div>
        </section>
        <section className="container my-5">
          <div className="container-fluid">
            <h2 className="line-title">Changement de cylindre</h2>
            <p>
              Vous emménagez dans un nouvel appartement ? Vous venez de subir
              une tentative d’effraction ? Vous avez perdu la clé de votre porte
              d’entrée ? Ou vous souhaitez simplement sécuriser l’accès à votre
              appartement ?
            </p>

            <p>
              Toutes ces raisons peuvent vous conduire à changer le{" "}
              <strong>cylindre</strong> de votre <strong>serrure</strong> pour
              l'ajout d'un <strong>cylindre</strong> hautement sécurisé à une{" "}
              <strong>serrure</strong> existante pour augmenter sa sécurité
              globale.
            </p>

            <p className="fw-bold">
              Privilégier un <strong>cylindre / barillet de sécurité</strong>
            </p>

            <p>
              Il en existe différents types. La Serrurerie Guy Môquet vous
              conseillera sur le type de <strong>cylindre</strong> à installer
              et vous fournira également de nouvelles clés, qui pourront être en
              reproduction protégée.
            </p>
            <p>
              Formé et qualifié, notre <strong>serrurier</strong> saura vous
              guider et vous conseiller sur votre changement de{" "}
              <strong>serrure</strong> en choisissant la{" "}
              <strong>serrure</strong> la plus optimale pour votre situation. Il
              effectuera un changement de <strong>serrure</strong> rapide et
              efficace et pourra vous installer une <strong>serrure</strong> de
              norme A2P qui augmentera la sécurité de votre domicile, et qui
              représente également une certification fiable auprès des
              assurances.
            </p>
            <p className="fw-bold">
              Vous pouvez augmenter la sécurité de votre appartement sans avoir
              à changer de porte. Les artisans de la Serrurerie Guy Môquet
              déterminent le budget nécessaire pour armer une porte afin que
              vous vous sentiez plus en sécurité à votre domicile.
            </p>
          </div>
          <div className="text-center mt-4">
            <DstButton
              value="Je demande un devis"
              clickFunction={() => navigate("/contact")}
            />
          </div>
        </section>
      </React.Fragment>
    </div>
  );
};

export default PoseSerrure;
