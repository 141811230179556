import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BanniereHeader from "../../components/banniereHeader/BanniereHeader";
import DstButton from "../../components/DstButton/DstButton";
import CardLink from "../../components/CardLink/CardLink";
import PictoContent from "../../components/Pictocontent/PictoContent";
import ReviewGoogle from "../../components/ReviewGoogle/ReviewGoogle";
import Carousel from "react-multi-carousel";
import "./Accueil.scss";
import { Helmet } from "react-helmet";

const index = () => {
  const jsonLdData = {
    "@context": "http://schema.org/",
    "@type": "LocalBusiness",
    name: "Serrurier Paris",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "150",
    },
  };

  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Serrurerie Guy Môquet - Serrurier paris 17</title>
        <meta
          name="description"
          content="Besoin d'un serrurier à Paris ? Serrurerie Guy Môquet propose des services professionnels de serrurerie à Paris et en Île-de-France."
        />
        <meta
          name="keywords"
          content="serrurier Paris, serrurerie Paris, dépannage serrurerie Paris, serrurier à Paris"
        />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <section>
        <BanniereHeader
          title="Serrurerie Guy Môquet"
          subTitle={
            <h2 className="h4">
              Prestation de Qualité Serrurier Agrée Picard <br />
              Dépannage Serrurerie 24h/24
            </h2>
          }
          backgroundImage="background-depannage.webp"
          link="/prestations"
          textButton="Découvrir nos Prestations"
        />
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <Row>
            <Col md="6">
              <h2>Qui sommes nous ?</h2>
              <p className="fw-bold">
                Située dans le 17<sup>e</sup> arr. de Paris, au{" "}
                <span className="text-nowrap">17 rue de la Jonquière</span>, la
                Serrurerie Guy Môquet offre un service de qualité depuis{" "}
                <strong>1995</strong>, spécialisée dans la sécurité
                résidentielle et commerciale.
              </p>
              <p>
                Entreprise familiale établie à Paris 17<sup>e</sup>, nous
                installons des produits de sécurité, tels que des clés,
                serrures, coffres-forts et alarmes, provenant de marques
                renommées. En cas d'urgence, nos artisans interviennent{" "}
                <strong>24h/24</strong> dans Paris et l’Île-de-France, avec des
                tarifs transparents et compétitifs.
              </p>
              <p>
                Les experts qualifiés de la Serrurerie Guy Môquet garantissent
                un service <strong>rapide, fiable et personnalisé</strong>, en
                mettant l'accent sur la satisfaction du client et la sécurité de
                son domicile ou de son entreprise.
              </p>
            </Col>
            <Col className="align-self-center" data-aos="fade">
              <img
                src="../../assets/images/boutique.webp"
                alt="Boutique de la Serrurerie Guy Môquet à Paris 17"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
        <div className="text-center mt-3 mt-md-0">
          <DstButton
            btnClass="text-center"
            value="Devis gratuit"
            clickFunction={() => navigate("/contact")}
          />
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2>Zone d’intervention</h2>
          <PictoContent
            image="../../assets/icons/zone.svg"
            alt="Zone d'intervention de la Serrurerie Guy Môquet en Île-de-France"
            description={
              <p className="ms-sm-3">
                Notre équipe{" "}
                <strong>intervient dans toute l’Île-de-France</strong> :{" "}
                <span className="text-nowrap">Paris (75)</span>
                <span>, </span>
                <span className="text-nowrap">Essonne (91)</span>
                <span>, </span>
                <span className="text-nowrap">Hauts-de-Seine (92)</span>
                <span>, </span>
                <span className="text-nowrap">Seine-Saint-Denis (93)</span>
                <span>, </span>
                <span className="text-nowrap">Val-de-Marne (94)</span>
                <span>, </span>
                <span className="text-nowrap">Val d’Oise (95)</span>
                <span>, </span>
                <span className="text-nowrap">Seine-et-Marne (77) </span>
                <span>et </span>
                <span className="text-nowrap">Yvelines (78).</span>
              </p>
            }
            dSmFlex
            centerVerticaly
          />
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Nos prestations</h2>
          <Row className="text-center h-100">
            <Col
              xs="6"
              lg="3"
              className="mb-2 px-1 xxs-mobile-100"
              data-aos="slide-up"
              data-aos-duration="500"
            >
              <CardLink
                link="depannage-serrurerie"
                image="ouverture-porte.webp"
                title="Ouverture de porte"
              />
            </Col>
            <Col
              xs="6"
              lg="3"
              className="mb-2 px-1 xxs-mobile-100"
              data-aos="slide-up"
              data-aos-duration="1000"
            >
              <CardLink
                link="pose-serrure"
                image="changement-serrure.webp"
                title="Changement de serrure"
              />
            </Col>
            <Col
              xs="6"
              lg="3"
              className="mb-2 px-1 xxs-mobile-100"
              data-aos="slide-up"
              data-aos-duration="1500"
            >
              <CardLink
                link="reproduction-cle"
                image="reproduction-cles.webp"
                title="Reproduction de clés"
              />
            </Col>
            <Col
              xs="6"
              lg="3"
              className="mb-2 px-1 xxs-mobile-100"
              data-aos="slide-up"
              data-aos-duration="2000"
            >
              <CardLink
                link="porte-blinde"
                image="porte-blinde.webp"
                title="Porte blindée"
              />
            </Col>
          </Row>
          <div className="text-center mt-3">
            <DstButton
              value="Toutes nos prestations"
              clickFunction={() => navigate("/prestations")}
            />
          </div>
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2>Nos engagements</h2>
          <Row className="mb-3">
            <Col>
              <PictoContent
                image="../../assets/icons/time-24h24.svg"
                title="Intervention rapide 24/24h"
                textCenter
                souligne
              />
            </Col>
            <Col>
              <PictoContent
                image="../../assets/icons/medail.svg"
                title="Professionnels qualifiés & expérimentés"
                textCenter
                souligne
              />
            </Col>
            <Col>
              <PictoContent
                image="../../assets/icons/euro.svg"
                title="Tarification honnête"
                textCenter
                souligne
              />
            </Col>
          </Row>
        </div>
      </section>
      {/* <section>
        <ElfsightWidget widgetID="875ffcec-2adb-440a-acf9-7f4a89367495" />;
      </section> */}
      <section className="container container my-5">
        <div className="container-fluid">
          <h2>Avis clients</h2>
          <ReviewGoogle />
        </div>
      </section>
      <section className="container container my-5">
        <div className="container-fluid marque">
          <h2 className="line-title">Nos Marques</h2>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all 1s"
            transitionDuration={1000}
            containerClass="carousel-partners"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
          >
            <div>
              <img
                src="../../assets/partner/vachette.png"
                alt="Vachette"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/picard.png"
                alt="Picard"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/jpm.png"
                alt="JMP"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/dierre.png"
                alt="Dierre"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/dormakaba.png"
                alt="Dormakaba"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/urmet.png"
                alt="Urmet"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/noralsy.png"
                alt="Noralsy"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/beugnot.png"
                alt="Beugnot"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/fichet.png"
                alt="fichet"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/pollux.png"
                alt="Pollux"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/mottura.png"
                alt="Mottura"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/bricard.png"
                alt="Bricard"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/heracles.png"
                alt="Heracles"
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="../../assets/partner/intratone.png"
                alt="Intratone"
                className="img-fluid"
              />
            </div>
          </Carousel>
        </div>
        <div className="text-center mt-3">
          <DstButton
            btnClass="mx-4"
            value="Contactez-nous"
            clickFunction={() => navigate("/contact")}
          />
          <DstButton
            btnClass="mx-4 mt-2 mt-sm-0"
            value="Découvrir nos prestations "
            variant="secondary"
            clickFunction={() => navigate("/prestations")}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default index;
